import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import getAuthInstance from "./auth"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const [userAuthenicated, setUserAuthenicated] = useState(
    getAuthInstance().isLoggedIn()
  )

  function handleMouseMove() {
    setUserAuthenicated(getAuthInstance().isLoggedIn())
  }

  useEffect(() => {
    getAuthInstance().on("auth_changed", handleMouseMove)
    return () => {
      getAuthInstance().off("auth_changed", handleMouseMove)
    }
  }, [])

  if (userAuthenicated === false && location.pathname !== `/login`) {
    navigate("/login")
    return null
  }

  return <Component {...rest} />
}

export default PrivateRoute
