import React from "react"
import Iframe from "react-iframe"

const DashboardDisplay = ({ reportId, pageId, width, height }) => {
  let url = `https://datastudio.google.com/embed/reporting/${reportId}/page/${pageId}`

  return (
    <Iframe
      width={width}
      height={height + 200}
      title={reportId}
      id={reportId}
      url={url}
      frameBorder="0"
      style={{ border: 0 }}
      allowFullScreen
    ></Iframe>
  )
}

export default DashboardDisplay
