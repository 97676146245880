import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

const Spinner = () => {
  return (
    <div
      style={{
        width: 200,
        paddingTop: 40,
        margin: "0 auto",
      }}
    >
      <FontAwesomeIcon icon={faSpinner} spin size="3x" />
    </div>
  )
}

export default Spinner
