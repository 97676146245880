import React from "react"
import useReportList from "../hooks/reportList"
import { useParams } from "@reach/router"
import Layout from "./layout"
import DashboardDisplay from "./dashboardDisplay"

export default () => {
  const response = useReportList()
  const params = useParams()

  let reportList = []
  let currentReport = null

  if (response.reportList !== null) {
    reportList = response.reportList
    currentReport = reportList.find(r => r.slug === params.slug)
    if (currentReport === undefined) {
      currentReport = null
    }
  }

  let ret = null

  if (currentReport !== null) {
    let pageId = currentReport.pages[0].id

    if (params.pageId) {
      pageId = params.pageId
    }

    ret = (
      <Layout
        title={currentReport.name}
        reportId={currentReport.id}
        activePageId={pageId}
        reportPages={currentReport.pages}
      >
        <DashboardDisplay
          slug={currentReport.slug}
          width={currentReport.width}
          height={currentReport.height}
          reportId={currentReport.id}
          pageId={pageId}
        />
      </Layout>
    )
  } else {
    ret = (
      <Layout>
        <div
          style={{
            textAlign: "center",
          }}
        >
          This report is no longer available.
        </div>
      </Layout>
    )
  }

  return ret
}
