import React from "react"

const DashboardTile = ({ name, slug, description }) => {
  return (
        <a
          title="Open in Google Data Studio"
          href={`https://dashboards.celtx.com/reports/${slug}`}
          target={"_blank"}
          rel="noopener noreferrer"
        >


      <div
        className="cx-dashboard-tile"
        style={{
          boxSizing: "border-box",
          width: 380,
          padding: 20,
          margin: 10,
          borderRadius: 5,
        }}
      >
        <div className="cx-dashboard-tile-title">{name}</div>
      </div>
      </a>
  )
}

export default DashboardTile
