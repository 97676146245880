import React from "react"
import { Helmet } from 'react-helmet'
import Layout from "./layout"
import { useQueryParam } from "gatsby-query-params";

export default () => {

  const filename = useQueryParam("id", "expansion.html"); // key, defaultValue

  const url = `/notebook?id=${filename}`
  
  let ret = (<div style={{height: "100%"}}>
    <Helmet>
    <style type="text/css">
      {`
        html, body {
          margin: 0; 
          padding: 0; 
          height: 100%;
          overflow: hidden;
        }
        
        #___gatsby, #gatsby-focus-wrapper, #gatsby-focus-wrapper div, #gatsby-focus-wrapper main {
          height: 100%;
        }
        
        #gatsby-focus-wrapper div {
          max-width: 100%;
        }
        
        #gatsby-focus-wrapper iframe {
          height: calc(100% - 121px);
          width: 100%;
          overflow: hidden;
        }
      `}
    </style>
  </Helmet>
      <iframe 
        src={url}
        title="Notebooks"
        frameBorder="0">
      </iframe>
  </div>)

  return <Layout>{ret}</Layout>
}
