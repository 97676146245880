import React, { useState, useEffect } from "react"
import { useFirebase } from "../hooks/useFirebase"
import { getFirebaseApp } from "./Firebase"
import getAuthInstance from "./auth"
import Logo from "./logo"
import { navigate } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGoogle } from "@fortawesome/free-brands-svg-icons"

const Login = () => {
  const [errorMessage, setErrorMessage] = useState(null)

  const [userAuthenicated, setUserAuthenicated] = useState(
    getAuthInstance().isLoggedIn()
  )

  function handleAuthenticationChange() {
    setUserAuthenicated(getAuthInstance().isLoggedIn())
  }

  useEffect(() => {
    getAuthInstance().on("auth_changed", handleAuthenticationChange)
    return () => {
      getAuthInstance().off("auth_changed", handleAuthenticationChange)
    }
  }, [])

  const firebase = useFirebase()

  const [firebaseInstance, setFirebaseInstance] = useState(null)

  useEffect(() => {
    if (firebase === null) {
      setFirebaseInstance(null)
    } else {
      setFirebaseInstance(firebase)
    }
  }, [firebase])

  async function doLogin() {
    const firebaseApp = getFirebaseApp()
    const googleProvider = new firebaseApp.auth.GoogleAuthProvider()
    googleProvider.setCustomParameters({
      hd: "celtx.com",
    })

    try {
      const result = await firebaseInstance
        .auth()
        .signInWithPopup(googleProvider)
      var user = result.user
      getAuthInstance().setUser(user)
    } catch (error) {
      if (error.code === "auth/popup-blocked") {
        setErrorMessage(error.message)
      } else if (error.code === "auth/popup-closed-by-user") {
        setErrorMessage(error.message)
      } else if (error.code === "auth/unauthorized-domain") {
        setErrorMessage(error.message)
      } else {
        setErrorMessage(error.message)
      }
    }
  }

  function handleSignIn() {
    if (firebaseInstance) {
      setErrorMessage(null)
      doLogin()
    }
  }

  if (userAuthenicated) {
    navigate("/")
  }

  let errorMessageDisplay

  if (errorMessage !== null) {
    errorMessageDisplay = (
      <div
        style={{
          width: 450,
          margin: "0 auto",
          marginTop: 30,
          padding: 10,
          border: "1px solid #ef5050",
          color: "#b70909",
          borderRadius: 5,
          fontSize: "80%",
        }}
      >
        <strong>{errorMessage}</strong>
      </div>
    )
  }

  return (
    <div>
      <div
        style={{
          width: 450,
          margin: "0px auto",
          border: "1px solid #000000",
          marginTop: 50,
          borderRadius: 5,
        }}
      >
        <div
          style={{
            backgroundColor: "#000000",
            padding: 5,
            textAlign: "center",
          }}
        >
          <Logo />
        </div>
        <div
          style={{
            paddingTop: 40,
            paddingBottom: 40,
          }}
        >
          <button
            style={{
              margin: "0px auto",
              width: 350,
              display: "block",
            }}
            onClick={handleSignIn}
          >
            <span
              style={{
                marginRight: 10,
              }}
            >
              <FontAwesomeIcon icon={faGoogle} />
            </span>{" "}
            Sign in with your @celtx.com account
          </button>
        </div>
      </div>
      {errorMessageDisplay}
    </div>
  )
}

export default Login
