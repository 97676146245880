import React from "react"

const getPath = function(slug, pageId, index) {
  var path = `/reports/${slug}`

  if (index !== 0) {
    path = path + `/page/${pageId}`
  }

  return path
}

const useReportList = () => {
  const [reportList, setReportList] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      try {
        const res = await fetch(`/report-list.json`)
        const json = await res.json()
        json.forEach(report => {
          report.pages.forEach((page, index) => {
            page.path = getPath(report.slug, page.id, index)
          })
          report.thumbnail = `https://datastudio.google.com/u/0/reporting/${report.id}/thumbnail`
        })
        
        setReportList(json)
        setIsLoading(false)
      } catch (error) {
        setError(error)
      }
    }
    fetchData()
  }, [])

  return {
    reportList,
    isLoading,
    error,
  }
}

export default useReportList
