import React from "react"
import Routes from "../components/Routes"
import { useLoadFirebase } from "../hooks/useFirebase"
import Spinner from "../components/spinner"

const App = () => {
  const isFirebaseLoaded = useLoadFirebase()
  return isFirebaseLoaded ? <Routes /> : <Spinner />
}

export default App
