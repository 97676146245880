import React, { useEffect, useState } from "react"
import { useFirebase } from "../hooks/useFirebase"
import { Router } from "@reach/router"
import Report from "../components/report"
import Notebooks from "../components/notebooks"
import Home from "../components/home"
import Login from "../components/login"
import PrivateRoute from "../components/privateRoute"
import getAuthInstance from "../components/auth"
import Spinner from "../components/spinner"

export default () => {
  const firebase = useFirebase()
  const [
    isUserAuthenicationInitialized,
    setUserAuthenicationInitialized,
  ] = useState(null)

  useEffect(() => {
    if (firebase == null) return
    return firebase.auth().onAuthStateChanged(user => {
      if (user) {
        getAuthInstance().setUser(user)
        firebase.auth().currentUser.getIdToken(true).then(function(token) {
          // set the __session cookie
          document.cookie = '__session=' + token + ';max-age=3600';
          setUserAuthenicationInitialized(true);
        });
      } else {
        document.cookie = '__session=';
        getAuthInstance().setUser(null);
        setUserAuthenicationInitialized(true);
      }
    })
  }, [firebase])

  const router = (
    <Router basepath="/">
      <PrivateRoute path="/reports/:slug/page/:pageId" component={Report} />
      <PrivateRoute path="/reports/:slug" component={Report} />
      <PrivateRoute path="/notebooks" component={Notebooks} />
      <PrivateRoute path="/" component={Home} />
      <Login path="/login" />
    </Router>
  )

  return isUserAuthenicationInitialized !== null ? router : <Spinner />
}
