import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import DashboardTile from "../components/dashboardTile"
import useReportList from "../hooks/reportList"

const IndexPage = () => {
  const response = useReportList()
  const [availableReportIds, setAvailableReportIds] = useState([])

  useEffect(() => {
    if (response.reportList === null) {
      return
    }

    const availableReports = response.reportList.map(dashboardObject => {
      return dashboardObject.id
    })

    setAvailableReportIds(availableReports)
      
  }, [response.reportList])

  let reportItems = []

  if (response.reportList !== null) {
    response.reportList.forEach(dashboardObject => {
      const d = dashboardObject
      if (availableReportIds.includes(d.id)) {
        reportItems.push(
          <DashboardTile
            key={d.slug}
            name={d.name}
            slug={d.slug}
            description={d.description}
          ></DashboardTile>
        )
      }
    })
  }

  let productDevelopmentDashboard = <a
      title="Open in Google Data Studio"
      href={`https://docs.google.com/spreadsheets/d/15_bsM2E_oxxufad9N0P8n4qMZFLQvN030buQ2ljoyr8`}
      target={"_blank"}
      rel="noopener noreferrer"
    >
    <div
    className="cx-dashboard-tile"
    style={{
      boxSizing: "border-box",
      width: 380,
      padding: 20,
      margin: 10,
      borderRadius: 5,
    }}
    >

      <div className="cx-dashboard-tile-title">Product Development Dashboard</div>
    </div>
  </a>;

  return (
    <Layout>
      <div
        style={{
          display: "flex",
          maxWidth: 1200,
          flexWrap: "wrap",
        }}
      >
        {reportItems}
        {productDevelopmentDashboard}
      </div>
    </Layout>
  )
}

export default IndexPage
